import React, { useEffect } from "react";
import Grid from "@mui/material/Grid";
import classes from "./Thankyou.module.css";
import tractor from "../../assets/images/tractor.gif";
import Container from "@mui/material/Container";
import { useNavigate, useLocation } from "react-router-dom";
import Typography from "@mui/material/Typography";
import ImgLogo from "../../assets/images/kishan_logo.svg";

export const Thankyou = () => {
  const navigate = useNavigate();
  const Id = sessionStorage.getItem("id");

  useEffect(() => {
    if (sessionStorage.getItem("currentKey") != 4) {
      switch (sessionStorage.getItem("currentKey")) {
        case "1":
          navigate("/");
          break;
        case "2":
          navigate("/otp");
          break;
        case "3":
          navigate("/register");
          break;
        case "4":
          navigate("/thankyou");
          break;
        default:
          navigate("/");
      }
    }
  }, []);
  return (
    <>
      <div className={classes["background-water-image"]}>
        <Container
          maxWidth="sm"
          style={{ height: "100%", position: "relative" }}
        >
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
            wrap="wrap"
            style={{height: "100%", position: "relative"}}
          >
            <div className={classes["thank-you"]}>
              <div className={classes["circle"]}>
                <img src={tractor} alt="" className={classes["img-gif"]} />
              </div>
              <img src={ImgLogo} alt="" className={classes["img-logo"]} />

              <Typography
                variant="h1"
                color="yellow"
                fontSize={30}
                fontWeight={900}
              >
                THANK YOU! / धन्यवाद!
              </Typography>
              <Typography variant="p" color="snow">
                Your submission has been received. We will be in touch and
                contact you soon. / आपका आवेदन प्राप्त हो गया है । हम जल्द ही
                आपसे संपर्क करेंगे।
              </Typography>
              <br />
              <Typography variant="p" color="snow">
                Your Registration id / आपकी पंजीकरण आईडी: {Id}
              </Typography>
            </div>
          </Grid>
        </Container>
      </div>
    </>
  );
};
