import React, { useState, useEffect } from "react";
import Img1 from "../../assets/images/water.jpg";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import classes from "./Register.module.css";
import ImgLogo from "../../assets/images/kishan_logo.svg";
import { toast } from "react-toastify";
import axios from "axios";
import { Country, State, City } from "country-state-city";
import { Carousel } from "react-responsive-carousel";
import { useNavigate, useLocation } from "react-router-dom";

export const Register = () => {
  const registrationAPIEndPoint =
    process.env.REACT_APP_API_DOMAIN + "/api/lead-forms";
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);

  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedState, setSelectedState] = useState();
  const [selectedCity, setSelectedCity] = useState();

  useEffect(() => {
    if (sessionStorage.getItem("currentKey") != 3) {
      switch (sessionStorage.getItem("currentKey")) {
        case "1":
          navigate("/");
          break;
        case "2":
          navigate("/otp");
          break;
        case "3":
          navigate("/register");
          break;
        case "4":
          navigate("/thankyou");
          break;
        default:
          navigate("/");
      }
    }
    const getStates = async () => {
      try {
        const result = await State.getStatesOfCountry("IN");
        let allStates = [];
        allStates = result?.map(({ isoCode, name }) => ({
          isoCode,
          name,
        }));
        const [{ isoCode: firstState = "" } = {}] = allStates;
        setCities([]);
        setSelectedCity("");
        setStates(allStates);
        setSelectedState(firstState);
      } catch (error) {
        setStates([]);
        setCities([]);
        setSelectedCity("");
      }
    };

    getStates();
  }, [selectedCountry]);

  useEffect(() => {
    const getCities = async () => {
      try {
        const result = await City.getCitiesOfState("IN", selectedState);
        let allCities = [];
        allCities = result?.map(({ name }) => ({
          name,
        }));
        const [{ name: firstCity = "" } = {}] = allCities;
        setCities(allCities);
        setSelectedCity(firstCity);
      } catch (error) {
        setCities([]);
      }
    };

    getCities();
  }, [selectedState]);

  const location = useLocation();
  const sessionId = sessionStorage.getItem("sessionId");
  const mobileNumber = sessionStorage.getItem("mobileNumber");
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    mobileNumber: mobileNumber || "",
    email: "",
    state: "",
    city: "",
    address: "",
    pincode: "",
    occupation: null,
    loan_required: null,
  });

  const handleChange = (event) => {
    const { name, value } = event.target;

    if (name === "first_name" || name === "last_name") {
      // Check for special characters in the first name and last name fields
      if (/[^a-zA-Z\s]/.test(value)) {
        // Remove special characters and update the state
        const cleanedValue = value.replace(/[^a-zA-Z\s]/g, "");
        setFormData({
          ...formData,
          [name]: cleanedValue,
        });
        return;
      }
    } else if (name === "email") {
      // Allow normal text in the email field and restrict special characters to "@" and "."
      const cleanedValue = value.replace(/[^a-zA-Z0-9@.]/g, "");
      setFormData({
        ...formData,
        [name]: cleanedValue,
      });
      return;
    } else if (name === "address") {
      // Allow alphabet, numbers, "-", "/", "[", "]"
      const cleanedValue = value.replace(/[^a-zA-Z0-9\-/\[\], ]/g, "");
      setFormData({
        ...formData,
        [name]: cleanedValue,
      });
      return;
    } else if (name === "pincode") {
      // Allow alphabet, numbers, "-", "/", "[", "]"
      const cleanedValue = value.replace(/[^0-9]/g, "");
      setFormData({
        ...formData,
        [name]: cleanedValue,
      });
      return;
    }

    // If no special characters were found, update the state
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  // Function to handle input change and validate pincode
  // Function to handle input change and validate pincode
  // Function to handle input change and validate pincode
  const handlePincodeChange = (e) => {
    const inputPincode = e.target.value;

    // Check if the inputPincode is empty
    if (inputPincode === "") {
      setFormData({ ...formData, pincode: inputPincode });
      return;
    }

    // Check if the inputPincode is a valid 6-digit number
    if (/^[0-9]{0,6}$/.test(inputPincode) && inputPincode.length <= 6) {
      setFormData({ ...formData, pincode: inputPincode });
    } else {
      toast.error("Pincode must be a 6-digit number!");
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validation checks
    if (
      formData.first_name === "" ||
      formData.last_name === "" ||
      formData.mobileNumber === "" ||
      formData.email === "" ||
      selectedState === "" ||
      selectedCity === "" ||
      formData.address === "" ||
      formData.pincode === ""
    ) {
      toast.error("All fields are required!");
      return;
    }

    // Mobile number validation
    const mobileNumberRegex = /^[0-9]{12}$/;
    if (!mobileNumberRegex.test(formData.mobileNumber)) {
      toast.error("Invalid mobile number format!");
      return;
    }

    // Email validation
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!emailRegex.test(formData.email)) {
      toast.error("Invalid email format!");
      return;
    }

    // Pincode validation
    if (formData.pincode.length !== 6) {
      toast.error("Pincode must be a 6-digit number!");
      return;
    }

    // Prepare the data to send to the API
    const leadFormData = {
      first_name: formData.first_name,
      last_name: formData.last_name,
      mobileNumber: formData.mobileNumber.toString(),
      email: formData.email,
      state: selectedState,
      city: selectedCity,
      address: formData.address,
      pincode: formData.pincode,
      occupation: formData.occupation,
      loan_required: formData.loan_required,
      sessionId: sessionId,
    };
    // Update this with your actual API endpoint

    try {
      const response = await axios.post(registrationAPIEndPoint, {
        data: {
          ...leadFormData,
        },
      });

      if (response.status === 200) {
        // Form submission was successful
        console.log("Form submission successful");
        // Redirect to the thankyou page

        sessionStorage.setItem("id", response.data.data.id);
        sessionStorage.setItem("currentKey", 4);
        navigate("/thankyou");
      } else {
        // Log the response status and data
        toast.error("An error occurred while submitting the form.");
      }
    } catch (error) {
      toast.error(error?.response?.data?.error?.message);
    }
  };

  useEffect(() => {
    // Disable the back button functionality
    window.history.pushState(null, null, window.location.href);
    window.onpopstate = function () {
      window.history.pushState(null, null, window.location.href);
    };
  }, []);
  return (
    <>
      <Grid container spacing={1}>
        <Grid item lg={6} md={6} xs={12}>
          <div className={classes['img-show']}>
            <img src={Img1} className={classes["img-wrapper-carousal"]} />
          </div>
        </Grid>

        <Grid item lg={6} md={6} xs={12}>
          <Container maxWidth="sm" className={classes["form-controlled"]}>
            <img src={ImgLogo} alt="" className={classes["kisan-icon"]} />

            <Typography
              variant="h1"
              color="initial"
              fontSize={25}
              fontWeight={700}
              paddingTop={3}
            >
              Registration / पंजीकरण
            </Typography>

            <Typography variant="p" color="gray" fontSize={15}>
              Enter your personal information / अपनी व्यक्तिगत जानकारी दर्ज करें
            </Typography>
            <form onSubmit={handleSubmit}>
              <Grid
                container
                spacing={3}
                direction="row"
                justifyContent="center"
                alignItems="center"
                alignContent="center"
                wrap="wrap"
                paddingTop={2}
              >
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="First Name / नाम*"
                    name="first_name"
                    type="text"
                    value={formData.first_name}
                    onChange={handleChange}
                    size="small"
                    InputProps={{ sx: { borderRadius: 3 } }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Last Name / उपनाम*"
                    name="last_name"
                    value={formData.last_name}
                    onChange={handleChange}
                    type="text"
                    InputProps={{ sx: { borderRadius: 3 } }}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Mobile Number / मोबाइल न.*"
                    name="mobileNumber"
                    value={mobileNumber}
                    type="text"
                    disabled
                    InputProps={{ sx: { borderRadius: 3 } }}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Email / ईमेल*"
                    name="email"
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                    InputProps={{ sx: { borderRadius: 3 } }}
                    size="small"
                  />
                </Grid>

                <Grid item xs={6}>
                  <FormControl fullWidth size="small">
                    <InputLabel>State / राज्य</InputLabel>
                    <Select
                      name="state"
                      type="text"
                      value={selectedState}
                      onChange={(event) => setSelectedState(event.target.value)}
                      style={{ borderRadius: 10 }}
                      label="State / राज्य"
                      inputProps={{
                        name: "state",
                        id: "state",
                      }}
                    >
                      {states.length > 0 ? (
                        states.map(({ isoCode, name }) => (
                          <MenuItem value={isoCode} key={isoCode}>
                            {name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value="">No state found</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth size="small">
                    <InputLabel>City / शहर</InputLabel>

                    <Select
                      name="city"
                      value={selectedCity}
                      style={{ borderRadius: 10 }}
                      type="text"
                      label="City / शहर"
                      onChange={(event) => setSelectedCity(event.target.value)}
                      inputProps={{
                        name: "city",
                        id: "city",
                      }}
                    >
                      {cities.length > 0 ? (
                        cities.map(({ name }) => (
                          <MenuItem value={name} key={name}>
                            {name}
                          </MenuItem>
                        ))
                      ) : (
                        <MenuItem value="">No cities found</MenuItem>
                      )}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Address / पता*"
                    name="address"
                    type="text"
                    value={formData.address}
                    onChange={handleChange}
                    InputProps={{ sx: { borderRadius: 3 } }}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Pincode / पिन कोड*"
                    name="pincode"
                    type="tel"
                    value={formData.pincode}
                    onChange={handlePincodeChange}
                    InputProps={{ sx: { borderRadius: 3 } }}
                    size="small"
                    renderInput={(props) => (
                      <input {...props} pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Occupation / व्यवसाय</InputLabel>
                    <Select
                      name="occupation"
                      value={formData.occupation}
                      onChange={handleChange}
                      type="text"
                      style={{ borderRadius: 10 }}
                      label="Occupation / व्यवसाय"
                    >
                      <MenuItem value="Farmer">Farmer</MenuItem>
                      <MenuItem value="Salaried">Salaried</MenuItem>
                      <MenuItem value="Self Employed">Self Employed</MenuItem>
                      <MenuItem value="Others">Others</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth size="small">
                    <InputLabel>Loan Required / अपेक्षित लोन</InputLabel>
                    <Select
                      name="loan_required"
                      value={formData.loan_required}
                      onChange={handleChange}
                      type="text"
                      style={{ borderRadius: 10 }}
                      label="Loan Required / अपेक्षित लोन"
                    >
                      <MenuItem value="New Tractor Loan">
                        New Tractor Loan
                      </MenuItem>
                      <MenuItem value="Used Tractor Loan">
                        Used Tractor Loan
                      </MenuItem>
                      <MenuItem value="Harvester Combine Loan">
                        Harvester Combine Loan
                      </MenuItem>
                      <MenuItem value="Used Commercial Vehicle Loan">
                        Used Commercial Vehicle Loan
                      </MenuItem>
                      <MenuItem value="Agri Implement Loan">
                        Agri Implement Loan
                      </MenuItem>
                      <MenuItem value="Other loans">Other Loans</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <button className={classes["send-button"]}>Submit</button>
              </Grid>
            </form>
          </Container>
        </Grid>
      </Grid>
    </>
  );
};
